import React, { useState } from "react";
import axios from "axios";

const ContactSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submit, setSubmit] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({
    name: "",
    email: "",
    message: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const hanldeSubmit = (e) => {
    e.preventDefault();
    setSubmit("");
    let errors = {};

    if (name.trim() === "") {
      errors.name = "Enter your full name";
    }

    if (email.trim() === "") {
      errors.email = "Enter your email address";
    } else if (!validateEmail(email.trim())) {
      errors.email = "Enter a valid email address";
    }

    if (message.trim() === "") {
      errors.message = "Please share your feedback. It helps us improve!";
    }

    if (Object.keys(errors).length > 0) {
      setError(errors);
      return;
    }

    setError({ name: "", email: "", message: "" });
    setLoader(true);
    axios
      .post("https://api.mixxerco.com/api/app/contact", {
        name: name,
        email: email,
        message: message,
      })
      .then((res) => {
        if (res.data.status) {
          setSubmit("Your query has been sent successfully");
          setTimeout(() => {
            setSubmit("");
          }, 2500);
        } else {
          console.log("Error sending message", res.data);
        }
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      })
      .finally(() => {
        setName("");
        setEmail("");
        setMessage("");
        setLoader(false);
      });
  };
  return (
    <>
      <div className="bg-brown1 rounded-md p-6 ">
        <h3 className="text-12xl max-sm:text-5xl text-dark font-lightBold mb-4 text-center">
          Have a question or need any assistance?
        </h3>
        <div className="lg:w-[66.1%] mx-auto text-center">
          <p className="text-2xl max-sm:text-md text-lightbrown font-normal mb-6">
            We're here to help! Please fill out the form below, and we'll get
            back to you as soon as possible.
          </p>
          {submit !== "" && (
            <div
              style={{ width: "fit-content" }}
              className="bg-opacity-90 mb-5 text-white px-8 py-2 mx-auto rounded-md shadow-md transition-opacity bg-green-500"
            >
              {submit}
            </div>
          )}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Full Name"
              value={name}
              className="w-full bg-brown2 px-4 py-3 rounded-md border-brown2 focus:outline-none placeholder:text-slate-600 focus:border-dark transition duration-300 ease-in-out"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <h6 className="text-start text-red-600 text-base mt-1">
              {error.name}
            </h6>
          </div>
          <div className="mb-4">
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              className="w-full bg-brown2 px-4 py-3 rounded-md border-brown2 focus:outline-none placeholder:text-slate-600 focus:border-dark transition duration-300 ease-in-out"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <h6 className="text-start text-red-600 text-base mt-1">
              {error.email}
            </h6>
          </div>
          <div className="mb-4 text-center">
            <textarea
              placeholder="Please provide details of your issue or feedback"
              value={message}
              className="w-full flex bg-brown2 px-4 py-3 rounded-md border-brown2 focus:outline-none placeholder:text-slate-600 focus:border-dark transition duration-300 ease-in-out resize-none"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <h6 className="text-start text-red-600 text-base mt-1">
              {error.message}
            </h6>

            <button
              onClick={hanldeSubmit}
              className="bg-primary text-white w-52 h-12 px-8 mt-4 rounded-md hover:bg-primary-dark hover:scale-105 transition duration-300 ease-in-out j-c-c mx-auto"
              disabled={loader}
            >
              {loader ? <span className="loaderWhite"></span> : "Contact Us"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSection;
